import React, {Component} from 'react';
import {defineMessages} from 'react-intl';
import ImageResultThumbnail from '../../components/common/ImageResultThumbnail';
import VideoResultThumbnail from '../../components/common/VideoResultThumbnail';
import ResultToolMenu from './ResultToolMenu';
import MediaPopout from '../../components/common/MediaPopout';
import ANCPopout from '../../components/common/ANCPopout';
import ANCIconButton from '../../components/common/ANCIconButton';
import ANCTypeLabel from '../../components/common/ANCTypeLabel';
import InternalLink from '../../components/common/InternalLink';
import {CONTENT_TYPE_NAMES} from '../../constants';
import HTMLString from '../../components/common/HTMLString';
import SaveToCollectionButton from '../../components/common/SaveToCollectionButton';
import FeatureOn from '../../components/feature-flag/FeatureOn';

const messages = defineMessages({
  searchDisplayImage: {
    id: 'SearchPage.display.image',
    defaultMessage: 'Image'
  },
  searchDisplayVideo: {
    id: 'SearchPage.display.video',
    defaultMessage: 'Video'
  }
});

export default class MultimediaSearchResultGridItem extends Component {
  state = {
    isPopupOpen: false
  };

  handleClick = (evt) => {
    evt.preventDefault();
    this.showPopup();
    this.props.clickHandler(this.props.eid, this.props.position)(evt);
  };

  handleVideoClick = (evt) => {
    this.props.clickHandler(this.props.eid, this.props.position)(evt);
  };

  showPopup = () => {
    this.setState({isPopupOpen: true});
  };

  handlePopoutClose = () => {
    this.setState({isPopupOpen: false});
  };

  render () {
    const isImage = this.props.type === CONTENT_TYPE_NAMES.IMAGE;
    const isVideo = this.props.type === CONTENT_TYPE_NAMES.VIDEO;
    const isANC = this.props.type === CONTENT_TYPE_NAMES.ANC;
    const mediaClassName = isANC ? 'c-ckm-search-results-grid__item-anc-icon' : 'c-ckm-search-results-grid__item-media';
    return (
      <li className='c-ckm-search-results-grid__item'>
        <ResultToolMenu />
        {this.state.isPopupOpen && !isANC && <MediaPopout handlePopoutClose={this.handlePopoutClose} {...this.props} />}
        {this.state.isPopupOpen && isANC && <ANCPopout handlePopoutClose={this.handlePopoutClose} {...this.props} />}
        <div className={mediaClassName}>
          {isImage && <ImageResultThumbnail {...this.props} onClick={this.handleClick} summary={this.props.summary} refimage={this.props.refimage} />}
          {isVideo && <VideoResultThumbnail {...this.props} onClick={this.handleVideoClick} summary={this.props.itemtitle} refimage={this.props.refimage} />}
          {isANC && <ANCIconButton showPopup={this.handleClick} type={this.props.sourcetype || this.props.srctype} intl={this.props.intl} itemtitle={this.props.itemtitle} sourcetitle={this.props.sourcetitle} size='large' />}
        </div>
        <div className='c-ckm-search-results__content'>
          <p className='c-ckm-search-results__content-type'>
            {isImage && this.props.intl.formatMessage(messages.searchDisplayImage)}
            {isVideo && this.props.intl.formatMessage(messages.searchDisplayVideo)}
            {isANC && <ANCTypeLabel type={this.props.sourcetype || this.props.srctype} />}
          </p>
          <div className='c-ckm-search-results__content-title-wrapper'>
            <h3 data-testid='mutimediaSearchResultGridItemTestId' className='c-ckm-search-results__content-title'>
              {isVideo
              ? (
                <InternalLink
                  className='c-ckm-search-results__content-title-button c-ckm-link'
                  href={`/content/video/${this.props.eid}`}
                  onClick={this.handleVideoClick}
                >
                  <HTMLString content={this.props.itemtitle} />
                </InternalLink>
              ) : (
                <button className='c-ckm-search-results__content-title-button c-ckm-link' onClick={this.handleClick}>
                  <HTMLString content={this.props.itemtitle} />
                </button>
              )}
            </h3>
            <FeatureOn featureName='collections'>
              <SaveToCollectionButton
                eid={this.props.eid}
                defaultCollectionId={this.props.defaultCollectionId}
                collections={this.props.collections}
                sourcetitle={this.props.itemtitle}
                type='multimedia'
                placement='top'
                updateCollections={this.props.updateCollections}
                updateDefaultCollectionId={this.props.updateDefaultCollectionId}
                isCollectionsLoading={this.props.isCollectionsLoading}
              />
            </FeatureOn>
          </div>
          <p className='c-ckm-search-results__content-summary'>{this.props.summary || this.props.summary_s}</p>
          <p className='c-ckm-search-results__content-source'>
            {isANC && this.props.hubeid
              ? <InternalLink href={`/content/toc/${this.props.hubeid}`}>{this.props.sourcetitle}</InternalLink>
              : this.props.sourcetitle}
          </p>
        </div>
      </li>
    );
  }
}

MultimediaSearchResultGridItem.displayName = 'MultimediaSearchResultGridItem';
