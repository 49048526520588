import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { defineMessages } from 'react-intl';
import useIntl from '../../hooks/use-intl';
import parse from 'html-react-parser';
import { Button } from '@els/els-react--button';
import { Icon } from '@els/els-react--icon';
import { Flyout } from '@els/els-react--flyout';
import CKApi from '../../services/api-helper';
import { MainContext } from '../../context/main-context';
import MediaPopoutSaveToCollection from './MediaPopoutSaveToCollection';
import { isDesktop } from '../../utils/elementUtils';

const messages = defineMessages({
  saveToCollectionButtonLabel: {
    id: 'saveButton.saveToCollectionButtonLabel',
    defaultMessage: 'Save to collection'
  },
  savedToCollectionButtonLabel: {
    id: 'saveButton.savedToCollectionButtonLabel',
    defaultMessage: 'Saved'
  },
  saveToCollectionButtonFlyout: {
    id: 'saveButton.saveToCollectionButtonFlyout',
    defaultMessage: 'Save to collection'
  },
  saveBookChapterButtonFlyout: {
    id: 'saveButton.saveBookChapterButtonFlyout',
    defaultMessage: 'Save book chapter to collection'
  },
  saveBookSectionButtonFlyout: {
    id: 'saveButton.saveBookSectionButtonFlyout',
    defaultMessage: 'Save book section to collection'
  },
  removeFromCollectionButtonFlyout: {
    id: 'saveButton.removeFromCollectionButtonFlyout',
    defaultMessage: 'Remove from collection'
  },
  addToCollectionBody: {
    id: 'saveButton.toast.addToCollectionBody',
    defaultMessage: 'Saved to {forLater} collection.'
  },
  forLater: {
    id: 'saveButton.toast.forLater',
    defaultMessage: 'For Later'
  },
  removeFromCollectionBody: {
    id: 'saveButton.toast.removeFromCollectionBody',
    defaultMessage: 'Removed from {forLater} collection.'
  },
  errorToastHeader: {
    id: 'saveButton.toast.errorSaveHeader',
    defaultMessage: 'Error'
  },
  errorSavingToCollectionToastBody: {
    id: 'saveButton.toast.errorSavingToCollectionToastBody',
    defaultMessage: 'There was an error trying to save. Please try again later.'
  },
  errorRemovingFromCollectionToastBody: {
    id: 'saveButton.toast.errorRemovingFromCollectionToastBody',
    defaultMessage: 'There was an error trying to remove. Please try again later.'
  },
  changeCollectionButton: {
    id: 'saveButton.toast.changeCollectionButton',
    defaultMessage: 'Change'
  },
  undoDelete: {
    id: 'saveButton.toast.undoDeleteButton',
    defaultMessage: 'Undo'
  }
});

const sharedSuccessToastProperties = {
  isVisible: true,
  autoHide: true,
  icon: '#icon-sprite_els-hmds-icon-checkmark',
  iconColor: 'positive',
  color: 'positive',
  hideAnchor: true,
  maxWidth: true
};

const sharedErrorToastProperties = {
  isVisible: true,
  autoHide: true,
  icon: '#icon-sprite_els-hmds-icon-alert-triangle',
  iconColor: 'negative',
  color: 'negative',
  hideAnchor: true
};

const SaveToCollectionButton = (props) => {
  const context = useContext(MainContext);
  const intl = useIntl();
  const [originCollectionsMapping, setOriginCollectionsMapping] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loadingItems, setLoadingItems] = useState([]);

  const addLoadingItem = (collectionId) => {
    setLoadingItems(prevState => [...prevState, collectionId]);
  };

  const removeLoadingItem = (collectionId) => {
    setLoadingItems(prevState => prevState.filter((item) => item !== collectionId));
  };

  const getFlyout = () => {
    if (checkIsSaved()) {
      return intl.formatMessage(messages.removeFromCollectionButtonFlyout);
    }
    switch (props.type) {
      case 'chapter':
        return intl.formatMessage(messages.saveBookChapterButtonFlyout);
      case 'section':
        return intl.formatMessage(messages.saveBookSectionButtonFlyout);
      default:
        return intl.formatMessage(messages.saveToCollectionButtonFlyout);
    }
  };

  const getOriginCollections = (collections) => {
    const originCollections = {};
    for (const collection of collections) { // eslint-disable-line no-unused-vars
      if (!collection.contents) {
        continue;
      }

      for (const content of collection.contents) { // eslint-disable-line no-unused-vars
        if (!(
          (props.type === 'section' && content.sectionId && content.eid === props.eid && content.sectionId === props.sectionId) ||
          (props.type !== 'section' && (content.sectionId === 'undefined' || !content.sectionId) && content.eid === props.eid)
        )) {
          continue;
        }
        originCollections[collection.id] = content.id;
        break;
      }
    }
    return originCollections;
  };

  const getCollectionName = (collectionName) => collectionName === 'For later' ? intl.formatMessage(messages.forLater) : collectionName;

  const checkIsSaved = () => Object.keys(originCollectionsMapping || {}).length;
  useEffect(() => {
    setOriginCollectionsMapping(null);
    if (!props.collections) {
      return;
    }
    const originCollections = getOriginCollections(props.collections);
    setOriginCollectionsMapping(originCollections);
    setDisabled(false);
  }, [props.collections, props.defaultCollectionId, props.eid]);

  const handleDeleteButton = (collectionId, matchingId, withoutToast, showButton) => {
    addLoadingItem(collectionId);

    CKApi.delete(`/student/api/collections/${collectionId}/contents/${matchingId}`)
      .then(({ data }) => {
        props.updateCollections();
        const id = uuid();
        const deleteBtnId = `undoDelete-${id}`;
        const collectionURL = `${context.productConfig.path}/collections?collectionId=${data.id}`;
        const keyAttribute = props.sectionId ? props.sectionId : '';

        if (!withoutToast && showButton) {
          context.addToast({
            id: deleteBtnId,
            body: `<div class='c-ckm-study-tools__toast-collection-body'>
            <div class='c-ckm-study-tools__toast-collection-text'>
            ${intl.formatMessage(messages.removeFromCollectionBody,
    {
      forLater: `<a class='c-ckm-study-tools__toast-collection-link' href=${collectionURL}>
            ${getCollectionName(data.name)}
            </a>`
    })}
    </div>
            <button type="button" id="${deleteBtnId}" key="${keyAttribute}" data-collection-id="${data.id}">
                ${intl.formatMessage(messages.undoDelete)}
              </button>
            </div>`,
            ...sharedSuccessToastProperties
          });
        }
        if (!withoutToast && !showButton) {
          context.addToast({
            body: `<div class='c-ckm-study-tools__toast-collection-body'>
            <div class='c-ckm-study-tools__toast-collection-text'>
            ${intl.formatMessage(messages.removeFromCollectionBody,
    {
      forLater: `<a class='c-ckm-study-tools__toast-collection-link' href=${collectionURL}>
            ${getCollectionName(data.name)}
            </a>`

    })}
    </div>
            </div>`,
            ...sharedSuccessToastProperties
          });
        }
        const changeButton = document.getElementById(deleteBtnId);
        const sectionId = changeButton.getAttribute('key');
        const collectionId = changeButton.getAttribute('data-collection-id');
        const toastId = changeButton.getAttribute('id');

        if (changeButton) {
          changeButton.addEventListener('click', (event) => {
            context.removeToast(toastId);
            handleSaveButton(collectionId, sectionId, props.sectionTitle, false);
          });
        }
      }).catch(() => {
        context.addToast({
          title: intl.formatMessage(messages.errorToastHeader),
          body: intl.formatMessage(messages.errorRemovingFromCollectionToastBody),
          ...sharedErrorToastProperties
        });
      }).finally(() => {
        removeLoadingItem(collectionId);
      });
  };

  const handleSaveButton = (collectionId, sectionId, sectionTitle, withoutToast, showButton) => {
    sectionId = sectionId && sectionId.length ? sectionId : undefined;

    addLoadingItem(collectionId);

    CKApi.put(`/student/api/collections/${collectionId}`, {
      eid: props.eid,
      sectionId: sectionId
    })
      .then(({ data }) => {
        props.updateCollections();
        props.updateDefaultCollectionId(collectionId);

        const id = uuid();
        const changeBtnId = `changeCollection-${id}`;
        if (!withoutToast && showButton) {
          const collectionURL = `${context.productConfig.path}/collections?collectionId=${data.id}`;
          context.addToast({
            body: `<div class='c-ckm-study-tools__toast-collection-body'>
              <div class='c-ckm-study-tools__toast-collection-text'>
                ${intl.formatMessage(messages.addToCollectionBody,
    {
      forLater: `<a class='c-ckm-study-tools__toast-collection-link' href=${collectionURL}>
                ${parse(data.name.toString())}
                </a>`
    })}
    </div>
              <button id='${changeBtnId}'>
                ${intl.formatMessage(messages.changeCollectionButton)}
              </button>
              </div>`,
            ...sharedSuccessToastProperties
          });
        }
        if (!withoutToast && !showButton) {
          const collectionURL = `${context.productConfig.path}/collections?collectionId=${data.id}`;
          context.addToast({
            body: `<div class='c-ckm-study-tools__toast-collection-body'>
                ${intl.formatMessage(messages.addToCollectionBody,
    {
      forLater: `<a class='c-ckm-study-tools__toast-collection-link' href=${collectionURL}>
                ${getCollectionName(data.name)}
                </a>`
    })}
              </div>`,
            ...sharedSuccessToastProperties
          });
        }
        const changeButton = document.getElementById(changeBtnId);
        if (!changeButton) {
          return;
        }
        changeButton.addEventListener('click', () => {
          props.updateCollections();
          setIsShowModal(true);
        });
      })
      .catch(() => {
        context.addToast({
          title: intl.formatMessage(messages.errorToastHeader),
          body: intl.formatMessage(messages.errorSavingToCollectionToastBody),
          ...sharedErrorToastProperties
        });
      }).finally(() => {
        removeLoadingItem(collectionId);
      });
  };

  const closeModal = () => {
    setIsShowModal(false);
  };

  const handleClick = (evt) => {
    evt.preventDefault();

    setDisabled(true);
    if (checkIsSaved()) {
      // Object.keys(originCollectionsMapping).forEach(collectionId => {
      //   handleDeleteButton(collectionId, originCollectionsMapping[collectionId], false, true);
      // }); //for reverting back to previous removing from collections functionality
      props.updateCollections();
      setIsShowModal(true);
    } else {
      handleSaveButton(props.defaultCollectionId, props.sectionId, props.sectionTitle, false, true);
    }
  };

  const getButton = () => {
    return (
      <Button
        onClick={handleClick}
        type={Button.Types.LINK}
        disabled={disabled}
        className='c-ckm-savebutton'
        id={checkIsSaved() ? 'remove-collection-btn' : 'add-collection-btn'}
        aria-expanded
      >
        <Icon
          a11y={{ name: (checkIsSaved() ? intl.formatMessage(messages.savedToCollectionButtonLabel) : intl.formatMessage(messages.saveToCollectionButtonLabel)) }}
          sprite={checkIsSaved() ? Icon.Sprites.STAR_SOLID : Icon.Sprites.STAR}
          className=''
          textAlignment='bottom'
          size={Icon.Sizes.XS}
          isVisible
          id='save-btn-icon'
        >
          {props.showLabel && (checkIsSaved() ? intl.formatMessage(messages.savedToCollectionButtonLabel) : intl.formatMessage(messages.saveToCollectionButtonLabel))}
        </Icon>
      </Button>
    );
  };

  return (
    <>
      {isDesktop()
        ? <Flyout
            className='c-ckm-savebutton__collections c-els-flyout--open'
            trigger='hover'
            theme='simple'
            placement={props.placement}
            flyout={getFlyout()}
            shouldCloseOnESC
            id='save-btn-flyout'
          >
          {getButton()}
        </Flyout>
        : <div className='c-ckm-savebutton__collections c-els-flyout--open'>
          {getButton()}
        </div>}
      {isShowModal &&
        <MediaPopoutSaveToCollection
          handleClose={closeModal}
          handleSaveButton={handleSaveButton}
          handleDeleteButton={handleDeleteButton}
          updateDefaultCollectionId={props.updateDefaultCollectionId}
          updateCollections={props.updateCollections}
          eid={props.eid}
          sectionId={props.sectionId}
          sectionTitle={props.sectionTitle}
          collections={props.collections}
          originCollections={originCollectionsMapping}
          loadingItems={loadingItems}
          type={props.type}
          isCollectionsLoading={props.isCollectionsLoading}
        />}
    </>
  );
};

export default SaveToCollectionButton;

SaveToCollectionButton.propTypes = {
  defaultCollectionId: PropTypes.number,
  collections: PropTypes.array,
  type: PropTypes.string,
  sectionId: PropTypes.string,
  updateCollections: PropTypes.func,
  eid: PropTypes.string.isRequired,
  sourcetitle: PropTypes.string.isRequired
};
